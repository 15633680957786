import React, { useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useLocalStorage } from 'react-use';

import { selectIsFeatureDiscoveryVisible } from '@selectors/favorites';
import { selectCategoriesData } from '@selectors/categories';

import { PRODUCTS_CATEGORY } from '@common/routes';
import { useMainMenu } from '../Navbar.helpers';

import { GlobalStyle, MenuStyled, MenuContent } from './Menu.styled';
import { CategoryMenuFirstLevel } from './CategoryMenuFirstLevel/CategoryMenuFirstLevel';
import { MENU_CLOSE_ANIMATION_DURATION } from './Menu.constants';
import { CategoryMenuSecondLevel } from './CategoryMenuSecondLevel/CategoryMenuSecondLevel';

const Menu = () => {
  const router = useRouter();
  const { isOpen, toggle } = useMainMenu();
  const isFavoritesPopperVisible = useSelector(selectIsFeatureDiscoveryVisible);

  const categories = useSelector(selectCategoriesData);

  const [activeCategory, setActiveCategory, removeActiveCategory] =
    useLocalStorage('activeCategory');

  const [openedSubmenu, setOpenedSubmenu] = useState(activeCategory);
  const [isSlideInAnimationEnabled, setIsSlideInAnimationEnabled] = useState(!activeCategory);

  useEffect(() => {
    if (isOpen) {
      setOpenedSubmenu(activeCategory);
    }
  }, [activeCategory, isOpen]);

  useEffect(() => {
    if (isOpen) {
      return undefined;
    }

    const timeoutId = setTimeout(() => {
      if (!isOpen && openedSubmenu) {
        setIsSlideInAnimationEnabled(activeCategory);
        setOpenedSubmenu(null);
      }
    }, MENU_CLOSE_ANIMATION_DURATION);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [activeCategory, isOpen, openedSubmenu]);

  useEffect(() => {
    if (!isOpen) {
      return undefined;
    }

    const handleEscapeKey = event => {
      if (event.keyCode === 27) {
        toggle(false);
      }
    };

    document.addEventListener('keydown', handleEscapeKey);

    return () => document.removeEventListener('keydown', handleEscapeKey);
  }, [isOpen, toggle]);

  useEffect(() => {
    const routerQuerySlug = router.query.slug;
    const activeCategorySlug =
      router.pathname === PRODUCTS_CATEGORY ?
        categories.find(
            ({ children, slug: categorySlug }) =>
              children.length > 1 &&
              children.find(
                ({ slug }) => slug === routerQuerySlug || categorySlug === routerQuerySlug
              )
          )?.slug :
        null;

    if (!activeCategorySlug) {
      removeActiveCategory();
    }
  }, [categories, removeActiveCategory, router]);

  return (
    <Transition
      in={isOpen}
      timeout={{
        enter: 0,
        exit: MENU_CLOSE_ANIMATION_DURATION,
      }}
    >
      {state => (
        <React.Fragment>
          <GlobalStyle
            isOpen={['entering', 'entered', 'exiting'].includes(state) || isFavoritesPopperVisible}
          />
          <MenuStyled
            isOpen={['entering', 'entered'].includes(state)}
            isExiting={state === 'exiting'}
          >
            <MenuContent>
              <CategoryMenuFirstLevel
                disableSlideInAnimation={isSlideInAnimationEnabled === false}
                menuSecondLevelIsOpen={!!openedSubmenu}
                animationQueueIsOpen={isOpen}
                setOpenedSubmenu={categorySlug => {
                  const match = categories.find(({ slug }) => categorySlug === slug);

                  setOpenedSubmenu(match.categoryId);
                  setActiveCategory(match.categoryId);

                  setIsSlideInAnimationEnabled(true);
                }}
                openedSubmenu={openedSubmenu}
                categories={categories}
              />
              <CategoryMenuSecondLevel
                menuSecondLevelIsOpen={Boolean(openedSubmenu) && Boolean(activeCategory)}
                animationQueueIsOpen={isOpen}
                setOpenedSubmenu={setOpenedSubmenu}
                setActiveCategory={setActiveCategory}
                category={categories.find(({ categoryId }) => categoryId === openedSubmenu)}
              />
            </MenuContent>
          </MenuStyled>
        </React.Fragment>
      )}
    </Transition>
  );
};

export default Menu;

import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import { selectBadgeCount } from '@selectors/customerInfo';
import { selectIsUserLogged } from '@store/selectors/auth';

import { getRouteUrl } from '@utils/urls';

import { PRE_LOGIN, VIP_HOME } from '@common/routes';
import { VIP as VIPIcon } from '@common/components/Icons';

import { IconLinkWrapper } from './common.styled';

const VipLink = () => {
  const { locale } = useRouter();
  const isLoggedIn = useSelector(selectIsUserLogged);
  const vipBadgeCount = useSelector(selectBadgeCount);

  return (
    <IconLinkWrapper hasIndicator={vipBadgeCount > 0}>
      <Link
        legacyBehavior
        href={
          isLoggedIn ?
            {
                pathname: VIP_HOME,
              } :
            {
                pathname: PRE_LOGIN,
                query: { target: getRouteUrl(locale, VIP_HOME) },
              }
        }
        as={
          isLoggedIn ?
            getRouteUrl(locale, VIP_HOME) :
            `${getRouteUrl(locale, PRE_LOGIN)}?target=${getRouteUrl(locale, VIP_HOME)}`
        }
      >
        <a>
          <VIPIcon fill="white" />
        </a>
      </Link>
    </IconLinkWrapper>
  );
};

export default VipLink;

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import getYear from 'date-fns/getYear';

import { selectLegalMenu } from '@selectors/menus';

import { PREFIXED_INTERNAL_LINK_DOMAINS } from '@utils/domains';
import { getLinkDataFromUrl } from '@utils/urls';

import { CreditsWrapper, MiddleCredits, CreditText } from './Credits.styled';

const Credits = () => {
  const { t } = useTranslation();
  const legalMenu = useSelector(selectLegalMenu);

  const year = useMemo(() => getYear(new Date()), []);

  return (
    <CreditsWrapper>
      <CreditText>
        {t('footer.copyrightsText', '© {{year}} Chicorée Mode AG', { year })}
      </CreditText>
      <MiddleCredits>
        {legalMenu?.menuItems.map(item => {
          const url =
            PREFIXED_INTERNAL_LINK_DOMAINS.some(domain => item.linkUrl?.includes(domain)) &&
            item.linkUrl;
          const linkData = url && getLinkDataFromUrl(url);

          if (linkData) {
            return (
              <Link
                legacyBehavior
                href={linkData.urlObject}
                as={linkData.as}
                passHref
                key={item.id}
              >
                <CreditText customAs="a">{item.title}</CreditText>
              </Link>
            );
          }

          return (
            <CreditText
              customAs="a"
              href={item.linkUrl}
              key={item.id}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.title}
            </CreditText>
          );
        })}
      </MiddleCredits>
      <CreditText as="a"
href="https://allink.ch/"
target="_blank"
rel="noopener noreferrer">
        {t('footer.madeByAllink', 'Made by allink')}
      </CreditText>
    </CreditsWrapper>
  );
};

export default Credits;

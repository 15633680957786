import React from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';

import { getRouteUrl } from '@utils/urls';

import { LOGO_CHICOREE_GREEN } from '@common/images';
import { HOME } from '@common/routes';

import { LogoLink, LogoImg } from './Logo.styled';

const Logo = () => {
  const { locale } = useRouter();

  return (
    <Link legacyBehavior
passHref
href={HOME}
as={getRouteUrl(locale, HOME)}
locale={false}>
      <LogoLink>
        <LogoImg src={LOGO_CHICOREE_GREEN}
width="102"
height="50" />
      </LogoLink>
    </Link>
  );
};

export default Logo;

import styled from 'styled-components';

import { centeredGridColumns, getGridBase, getThemeColor, media } from '@utils/styled';

import Typography from '@common/components/Typography';

export const ContentContainer = styled.div`
  ${getGridBase(12)};
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 1)};
  word-break: break-word;
  hyphens: auto;
`;

export const Content = styled.div`
  grid-column: ${centeredGridColumns(12)};
  ${({ hasImage }) => hasImage && getGridBase(12)};
  max-width: 100%;
`;

export const Header = styled.div`
  grid-column: ${centeredGridColumns(12)};
  ${({ hasImage, theme }) => (hasImage ? theme.getFluidSpacing('margin-top', 'scale', 3) : null)};
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 1)};

  ${media.from640up`
    grid-column: ${centeredGridColumns(10)};
  `}

  ${media.from1200up`
    grid-column: ${centeredGridColumns(8)};
  `}
`;

export const Title = styled(Typography).attrs({
  customAs: 'h1',
  variant: 'h2',
})``;

export const Subtitle = styled(Typography).attrs({
  variant: 'body2',
})`
  color: ${getThemeColor('bluegrey.60')};
`;

export const Lead = styled(Typography).attrs({
  variant: 'subtitle2',
})`
  margin-bottom: 0;
  font-weight: 400;
`;
